<template>
  <div class="home">
    <div class="head flex flex-jsb">
      <div class="head-l flex flex-ac" @click="handleFullScreen"><i class="icon "
          :class="isFullScreen ? 'icon-quxiaoquanping' : 'icon-quanping'" />
        {{ isFullScreen ? '关闭全屏' : '全屏显示' }}</div>
      <div class="head-title">智慧停车云岗亭</div>
      <div class="head-r">{{ user.name }}</div>
      <!-- <button @click="handleLoginout">退出</button> -->
    </div>
    <div class="content flex flex-jsb" v-loading="loading">

      <ul class="video-box flex flex-w flex-acb" v-if="gateData.length <= 16">
        <li :class="gateData.length > 9 ? 'li4' : (gateData.length > 4 ? 'li3' : '')" v-for="(item, index) in gateData"
          :key="index">
          <template v-if="item.device && item.device.model_name == 'ice'">
            <img :ref="'iceRef' + item.device.id" />
          </template>
          <!-- <template v-else-if="item.device && item.device.model_name == 'qy'">
            <div class="iframe">
              <img class="video-item" :ref="'iframe' + item.device.id" :src="item.device.local_addr" />
            </div>
          </template> -->
          <template v-else-if="item.device && (item.device.model_name == 'qy' || item.device.model_name == 'hc')">
            <div :id="'rtsp' + item.device.id + index" class="video-item">
              <label
                v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
            </div>
          </template>
          <template
            v-else-if="item.device && item.device.local_addr && (item.device.local_addr.includes('.flv') || item.device && item.device.local_addr.includes('.php'))">
            <div class="video-item" :id="'video' + item.id">
              <label
                v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
            </div>
          </template>
          <template v-else-if="item.device && item.device.local_addr && item.device.local_addr.includes('.m3u8')">
            <video class="video-item" v-show="item.device.local_addr" controls autoplay muted
              :id="'myVideo' + item.id + index">
              <source v-if="item.device.local_addr" :src="item.device.local_addr" type="application/x-mpegURL" />
            </video>
          </template>
          <div v-else-if="item.imageSrc" class="video-item">
            <img :src="item.imageSrc" />
          </div>
          <div v-else class="video-item"><label class="off-line">设备离线</label></div>
          <div class="funtion flex flex-jsb flex-ac">
            <div class="funtion-title">
              [{{ item.type }}]{{ item.name }}
            </div>
            <div class="funtion-button flex">
              <div>
                <el-popover v-if="item.type.includes('入')" placement="top-start" :width="200" trigger="hover"
                  content="如果识别区域没有车牌可供识别，触发后会上报无牌车识别记录，可以用于无牌车手动触发">
                  <template #reference>
                    <el-button type="primary" size="mini" :loading="recognizeLoading"
                      @click="handleRecognize($event, item.id)">手动触发识别</el-button>
                  </template>
                </el-popover>
              </div>
              <div>
                <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                  @click="handleOpenAndClose($event, item.id, 0)">开闸</el-button>
                <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                  @click="handleOpenAndClose($event, item.id, 1)">落闸</el-button>
              </div>
            </div>
          </div>
        </li>

      </ul>
      <el-carousel class="video-box" v-else indicator-position="outside" height="910px" :loop="false" :autoplay="false">
        <el-carousel-item>
          <ul class=" video-carousel flex flex-w flex-acb">
            <li :class="gateData.length > 9 ? 'li4' : (gateData.length > 4 ? 'li3' : '')"
              v-for="(item, index) in gateData.slice(0, 16)" :key="index">
              <template v-if="item.device && item.device.model_name == 'ice'">
                <img :ref="'iceRef' + item.device.id" />
              </template>
              <!-- <template v-else-if="item.device && item.device.model_name == 'qy'">
                <div class="iframe">
                  <img class="video-item" :ref="'iframe' + item.device.id" :src="item.device.local_addr" />
                </div>
              </template> -->
              <template v-else-if="item.device && (item.device.model_name == 'qy' || item.device.model_name == 'hc')">
                <div :id="'rtsp' + item.device.id + index" class="video-item">
                  <label
                    v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
                </div>
              </template>
              <template
                v-else-if="item.device && item.device.local_addr && (item.device.local_addr.includes('.flv') || item.device && item.device.local_addr.includes('.php'))">
                <div class="video-item" :id="'video' + item.id">
                  <label
                    v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
                </div>
              </template>
              <template v-else-if="item.device && item.device.local_addr && item.device.local_addr.includes('.m3u8')">
                <video class="video-item" v-show="item.device.local_addr" controls autoplay muted
                  :id="'myVideo' + item.id + index">
                  <source v-if="item.device.local_addr" :src="item.device.local_addr" type="application/x-mpegURL" />
                </video>
              </template>
              <div v-else-if="item.imageSrc" class="video-item">
                <img :src="item.imageSrc" />
              </div>
              <div v-else class="video-item"><label class="off-line">设备离线</label></div>
              <div class="funtion flex flex-jsb flex-ac">
                <div class="funtion-title">
                  [{{ item.type }}]{{ item.name }}
                </div>
                <div class="funtion-button flex">
                  <div>
                    <el-popover v-if="item.type.includes('入')" placement="top-start" :width="200" trigger="hover"
                      content="如果识别区域没有车牌可供识别，触发后会上报无牌车识别记录，可以用于无牌车手动触发">
                      <template #reference>
                        <el-button type="primary" size="mini" :loading="recognizeLoading"
                          @click="handleRecognize($event, item.id)">手动触发识别</el-button>
                      </template>
                    </el-popover>
                  </div>
                  <div>
                    <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                      @click="handleOpenAndClose($event, item.id, 0)">开闸</el-button>
                    <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                      @click="handleOpenAndClose($event, item.id, 1)">落闸</el-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </el-carousel-item>
        <el-carousel-item>
          <ul class=" video-carousel flex flex-w flex-acb">
            <li :class="gateData.length > 9 ? 'li4' : (gateData.length > 4 ? 'li3' : '')"
              v-for="(item, index) in gateData.slice(16, 32)" :key="index">
              <template v-if="item.device && item.device.model_name == 'ice'">
                <img :ref="'iceRef' + item.device.id" />
              </template>
              <!-- <template v-else-if="item.device && item.device.model_name == 'qy'">
                <div class="iframe">
                  <img class="video-item" :ref="'iframe' + item.device.id" :src="item.device.local_addr" />
                </div>
              </template> -->
              <template v-else-if="item.device && (item.device.model_name == 'qy' || item.device.model_name == 'hc')">
                <div :id="'rtsp' + item.device.id + index" class="video-item">
                  <label
                    v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
                </div>
              </template>
              <template
                v-else-if="item.device && item.device.local_addr && (item.device.local_addr.includes('.flv') || item.device && item.device.local_addr.includes('.php'))">
                <div class="video-item" :id="'video' + item.id">
                  <label
                    v-if="flvfail">地址栏输入chrome://flags/#block-insecure-private-network-requests，将选项Default改为禁用Disable，然后重启浏览器即可。</label>
                </div>
              </template>
              <template v-else-if="item.device && item.device.local_addr && item.device.local_addr.includes('.m3u8')">
                <video class="video-item" v-show="item.device.local_addr" controls autoplay muted
                  :id="'myVideo' + item.id + index">
                  <source v-if="item.device.local_addr" :src="item.device.local_addr" type="application/x-mpegURL" />
                </video>
              </template>
              <div v-else-if="item.imageSrc" class="video-item">
                <img :src="item.imageSrc" />
              </div>
              <div v-else class="video-item"><label class="off-line">设备离线</label></div>
              <div class="funtion flex flex-jsb flex-ac">
                <div class="funtion-title">
                  [{{ item.type }}]{{ item.name }}
                </div>
                <div class="funtion-button flex">
                  <div>
                    <el-popover v-if="item.type.includes('入')" placement="top-start" :width="200" trigger="hover"
                      content="如果识别区域没有车牌可供识别，触发后会上报无牌车识别记录，可以用于无牌车手动触发">
                      <template #reference>
                        <el-button type="primary" size="mini" :loading="recognizeLoading"
                          @click="handleRecognize($event, item.id)">手动触发识别</el-button>
                      </template>
                    </el-popover>
                  </div>
                  <div>
                    <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                      @click="handleOpenAndClose($event, item.id, 0)">开闸</el-button>
                    <el-button type="primary" size="mini" :loading="openAndCloseLoading"
                      @click="handleOpenAndClose($event, item.id, 1)">落闸</el-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>

      <div class="info">
        <ul class="info-statistics flex flex-ac flex-jsb">
          <li>
            <label>{{ remainData.left }}</label>
            <p>剩余车位</p>
          </li>
          <li v-for="(item, index) in remainData.list" :key="index">
            <label>{{ item.count }}</label>
            <p>{{ item.name }}</p>
          </li>
        </ul>
        <div class="info-car">
          <div class="info-car-title">入口信息</div>
          <div class="info-car-data">
            <ul class="flex flex-w">
              <li class="flex">
                <label>车牌号：</label>
                <p>{{ recordsEntData.car_number }}</p>
              </li>
              <li class="flex">
                <label>车辆类型：</label>
                <p>{{ recordsEntData.type_name || recordsEntData.car_type }}</p>
              </li>
              <li class="flex">
                <label>入场通道：</label>
                <p>{{ recordsEntData.ent_gate_name || recordsEntData.gate_id }}</p>
              </li>
              <li class="flex">
                <label>入场时间：</label>
                <p>{{ recordsEntData.ent_time || recordsEntData.record_time }}</p>
              </li>
              <li class="flex">
                <label>备注信息：</label>
                <p>{{ recordsEntData.memo }}</p>
              </li>
              <li class="flex">
                <label>抓拍图片：</label>
                <p class="preview-img" @click="previewImg(recordsEntData.ent_pic || recordsEntData.pic)">
                  查看图片
                </p>
              </li>
              <li class="quick">
                <el-button type="primary" :loading="dialogVisibleLookup" :disabled="!recordsEntData.memo"
                  @click="handleQuick">快捷入场(R)</el-button>
              </li>
            </ul>
          </div>
          <div class="info-car-title flex flex-jsb flex-ac">出口信息
            <el-dropdown placement="bottom" :disabled="pendingList.length - 1 == 0">
              <el-button type="primary" size="small">
                待处理({{ pendingList.length > 0 ? (pendingList.length - 1) : 0 }})
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in pendingList" :key="item.id"
                    v-show="item.gate_id != recordsDepData.gate_id" @click="handlePending(item)">{{ item.car_number
                    }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="info-car-data">
            <ul class="flex flex-w">
              <li class=" flex">
                <label>车牌号：</label>
                <p>{{ recordsDepData.car_number }}</p>
              </li>
              <li class="flex">
                <label>车辆类型：</label>
                <p>{{ recordsDepData.type_name || recordsDepData.car_type }}</p>
              </li>
              <li class="flex">
                <label>入场通道：</label>
                <p>{{ recordsDepData.ent_gate_name }}</p>
              </li>
              <li class="flex">
                <label>入场时间：</label>
                <p>{{ recordsDepData.ent_time || recordsDepData.record_time }}</p>
              </li>
              <li class="flex">
                <label>出场通道：</label>
                <p>{{ recordsDepData.dep_gate_name }}</p>
              </li>
              <li class="flex">
                <label>停车时长：</label>
                <p>{{ recordsDepData.time_long || recordsDepData.park_time_long || recordsDepData.park_time }}</p>
              </li>
              <li class="flex">
                <label>订单金额：</label>
                <p>{{ recordsDepData.price || 0 }}元</p>
              </li>
              <li class="flex">
                <label>已付金额：</label>
                <p>{{ recordsDepData.aly_pay_price || 0 }}元</p>
              </li>
              <li class="flex">
                <label>优惠金额：</label>
                <p>{{ recordsDepData.cut_price || 0 }}元</p>
              </li>
              <li class="flex">
                <label>备注信息：</label>
                <p>{{ recordsDepData.memo }}</p>
              </li>
              <li class="info-car-cost">
                <label>待缴金额:</label>
                <p>{{ recordsDepData.pay_price || 0 }}元</p>
              </li>
              <li class="flex">
                <label>抓拍图片：</label>
                <p class="preview-img" @click="previewImg(recordsDepData.dep_pic || recordsDepData.pic)">
                  查看图片
                </p>
              </li>

            </ul>
            <div class="info-car-button flex">
              <el-button type="primary" border :disabled="recordsDepData.pay_price == 0" :loading="prePayLoading"
                @click="handlePrePay(recordsDepData)">现金放行(U)</el-button>
              <el-button type="primary" :disabled="recordsDepData.pay_price == 0" :loading="disposeLoading"
                @click="handelDispose(recordsDepData)">不予处理</el-button>
              <!-- <el-button type="primary" plain @click="handelRefresh">刷新订单</el-button> -->
            </div>
            <el-form class="info-car-reason" :inline="true">
              <el-form-item label="免费原因：">
                <el-select filterable allow-create default-first-option v-model="freeOut" placeholder="请选择免费原因"
                  style="width:267px">
                  <el-option v-for="(item, index) in freeOutList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="medium" :disabled="recordsDepData.pay_price == 0"
                  :loading="freeOutLoading" @click="handleFreeOut(recordsDepData)">免费放行(p)</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="next">
      上一页下一页
    </div> -->
    <div class="footer flex flex-jsb">
      <ul>
        <li @click="handleOutAndEnterRecord('enter')"><i class="icon icon-ru" style="color:#07AD32" />入场记录(Z)</li>
        <li @click="handleOutAndEnterRecord('out')"><i class="icon icon-icon_function_chuku"
            style="color:#0BA4DD" />出场记录(X)
        </li>
      </ul>
      <ul>
        <li @click="handleOutEnter('enter')"><i class="icon icon-ruchang1" style="color:#07AD32" />手动入场(V)</li>
        <li @click="handleOutEnter('out')"><i class="icon icon-chuchang" style="color:#0BA4DD" />手动出场(B)</li>
        <li @click="handleOutAndEnterRecord('bepresent')"><i class="icon icon-jidongchechang"
            style="color:#DE5A1F" />在场车辆(N)</li>
      </ul>
      <ul>
        <li @click="$refs.dialogHandover.init"><i class="icon icon-jiaohuan" style="color:#981EFF" />交班(K)</li>
        <li @click="handleLock"><i class="icon icon-taocan-suoxiao" style="color:#984D11" />锁屏(L)</li>
      </ul>
    </div>
    <dialogSentry ref="dialogSentry" title="请选择值班岗亭" :close="false" @confirm="confirmSentry" />

    <dialogLock ref="dialogLock" title="解锁" :close="false" @confirm="confirmLock" />
    <dialogOutEnter ref="dialogOutEnter" :title="outEnterTitle" :freeOutList="freeOutList"
      @upInRecord="handleOutAndEnterRecord('bepresent')" />
    <dialogOutEnterRecord ref="dialogOutEnterRecord" :title="outEnterTitle" @appearance="appearance" />
    <dialogHandover ref="dialogHandover" />
    <PreviewImg ref="PreviewImg" />
  </div>
</template>

<script>
import dialogSentry from '@/views/login/dialog-sentry'
import dialogLock from '@/views/login/dialog-lock'
import dialogOutEnter from '@/views/admin/dialog-out-enter'
import dialogOutEnterRecord from '@/views/admin/dialog-out-enter-record'
import dialogHandover from '@/views/admin/dialog-handover'
import PreviewImg from '@/components/PreviewImg'
import * as API_index from "@/api/index";
import * as API_login from "@/api/login";
import { setTimeout } from 'timers';
import axios from 'axios'
import store from '@/store'
import '@/utils/mqttws31'
import JSEncrypt from '@/static/js/jsencrypt.min.js'
import { nextTick } from 'vue';
let Base64 = require('js-base64').Base64
import lmd5 from '@/utils/lmd5'
export default {
  name: 'Home',
  components: {
    dialogSentry,
    dialogLock,
    dialogOutEnter,
    dialogOutEnterRecord,
    dialogHandover,
    PreviewImg
  },
  data() {
    return {
      timer: '', //定时器
      loading: true,
      user: {},
      park_id: this.getStore('park_id'),
      sentry_id: this.getStore('sentry_id'),
      gateData: [],
      pendingList: [], //待处理车辆记录
      remainData: {}, //现场车辆统计数据
      recordsEntData: {}, //入口现场车辆信息
      recordsDepData: {
        extra: {}
      }, //出场现场车辆信息
      prePayLoading: false, //现金放行状态
      disposeLoading: false, //不予处理状态
      freeOutLoading: false, //免费放行状态
      freeOutList: [], //免费放行原因列表
      freeOut: '', //免费放行原因
      lockState: false, //锁屏状态
      isFullScreen: false, //是否全屏
      outEnterTitle: '',//弹窗名
      subTopic: "/vel/receive/", //订阅主题
      pubTopic: "/vel/info", //发布主题
      flvfail: false, //跨域时
      recognizeLoading: false, //手动触发识别
      openAndCloseLoading: false, //开闸关闸状态
      dialogVisibleLookup: false,//快捷入口状态
      isFirefox: navigator.userAgent.includes('Firefox'), //是否火狐
      decrypt: new JSEncrypt()
    }
  },
  created() {
    document.addEventListener('keydown', this.keydown, false)
    console.info(" park_id  =====>>>> 2", this.park_id)
    console.info(" sentry_id  =====>>>> ", this.sentry_id)
    this.user = JSON.parse(this.getStore('user')).user
    // this.subTopic = this.subTopic + this.park_id
    // if (this.hasSentry()) {
    //   console.info(" sentry_id  =====>>>> ", this.sentry_id)
    //   this.subTopic = this.subTopic + '/' + this.sentry_id;
    // } else {
    //   this.subTopic = this.subTopic + "/#"
    // }
    this.decrypt.setPrivateKey(process.env.VUE_APP_RASKEY)

  },
  mounted() {

    this.init()
    if (store.state.mqtt) {
      this.subTopic = store.state.mqtt.mqtt_recv_topics.split(';')[0]
      this.pubTopic = store.state.mqtt.mqtt_send_topic
      this.MQTTConnect();
      this.timer = setInterval(this.heartBeat, store.state.mqtt.mqtt_keep_live * 1000);//30秒发送一次心跳
    }
    window.addEventListener('beforeunload', () => {
      this.disConnect();
    });
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.disConnect();
    document.removeEventListener('keydown', this.keydown, false)

  },
  methods: {
    keydown(e) {
      if (document.body.className == 'el-popup-parent--hidden' || document.getElementsByClassName('el-input--suffix')[0].className == 'el-input el-input--suffix is-focus') {
        return false
      }
      if (e.code == 'KeyR') {
        if (this.recordsEntData.memo) {
          this.handleQuick()
        }
      } else if (e.code == 'KeyU') {
        if (this.recordsDepData.pay_price > 0) {
          this.handlePrePay(this.recordsDepData)
        }
      } else if (e.code == 'KeyI') {
        console.log('I')
      } else if (e.code == 'KeyP') {
        if (this.recordsDepData.pay_price > 0) {
          this.handleFreeOut(this.recordsDepData)
        }
      } else if (e.code == 'KeyZ') {
        this.handleOutAndEnterRecord('enter')
      } else if (e.code == 'KeyX') {
        this.handleOutAndEnterRecord('out')
      } else if (e.code == 'KeyV') {
        this.handleOutEnter('enter')
      } else if (e.code == 'KeyB') {
        this.handleOutEnter('out')
      } else if (e.code == 'KeyN') {
        this.handleOutAndEnterRecord('bepresent')
      } else if (e.code == 'KeyK') {
        this.$refs.dialogHandover.init()
      } else if (e.code == 'KeyL') {
        this.handleLock()
      }
    },
    MQTTConnect() {
      sessionStorage.setItem('new', true)
      const host = this.decrypt.decrypt(store.state.mqtt.mqtt_url).split('//')[1].split('/')[0].split(':')[0];
      const port = parseInt(this.decrypt.decrypt(store.state.mqtt.mqtt_url).split(':').pop().split('/')[0]);
      this.mqtt = new Paho.MQTT.Client(  //实例化一个对象
        host,
        port,
        "pc-client-" + this.user.id //this.getUuid(),  //防止多个浏览器打开，导致的问题，保证唯一性
      );
      var options = {
        timeout: 10,
        useSSL: false,
        cleanSession: true,
        //如果为false(flag=0)，Client断开连接后，Server应该保存Client的订阅信息。如果为true(flag=1)，表示Server应该立刻丢弃任何会话状态信息。
        onSuccess: this.onConnect,
        onFailure: function (message) {
          //连接失败定时重连
          console.info(" message ", message)
          // setTimeout(this.MQTTconnect, this.reconnectTimeout);
        },
        keepAliveInterval: 60,
        reconnect: true,         // Enable automatic reconnect
        // reconnectInterval: 10     // Reconnect attempt interval : 10 seconds
      };
      this.mqtt.onConnectionLost = this.onConnectionLost;
      this.mqtt.onMessageArrived = this.onMessageArrived;
      //用户名和密码的验证，我这里都为空；不加验证
      const username = "admin"
      if (username != null) {
        options.userName = this.decrypt.decrypt(store.state.mqtt.mqtt_user)
        options.password = this.decrypt.decrypt(store.state.mqtt.mqtt_pwd)
      }
      this.mqtt.connect(options);
    },
    //uuid随机生成
    getUuid() {
      var uid = [];
      var hexDigits = "0123456789abcdefghijklmnopqrst";
      for (var i = 0; i < 32; i++) {
        uid[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      uid[6] = "4";
      uid[15] = hexDigits.substr((uid[15] & 0x3) | 0x8, 1);
      var uuid = uid.join("");
      return uuid;
    },
    //连接
    onConnect() {
      console.log("onConnected  ");
      //连接成功，订阅信息
      console.log(" 订阅信息  subTopic  " + this.subTopic, this.mqtt);
      const dataList = store.state.mqtt.mqtt_recv_topics.split(';')
      for (let i in dataList) {
        this.mqtt.subscribe(dataList[i]);
      }

      //获取初始化数据(包含余位信息，最新的进出口信息)
      this.getParkInitData();
    },

    //主动断开连接
    disConnect() {
      console.log("disConnect  ");
      this.mqtt.disconnect();
    },



    //发送mqtt消息
    sendMqttReq(params) {
      this.mqtt.send(this.pubTopic, JSON.stringify(params), 0);
    },

    //获取余位数据
    getParkRemain() {
      const date = new Date().getTime()
      let remain = {
        pkg_id: this.mqtt.clientId,
        pkg_type: 'REQ',
        cmd: 'motor_remain_notify',
        bus_type: store.state.mqtt.mqtt_recv_topics.split('/')[1],
        client_type: store.state.mqtt.mqtt_client_id.split('-')[0],
        client_id: store.state.mqtt.mqtt_client_id.split('-').pop(),
        timestamp: date,
        version: '1.0',
        sign: this.getSign(date),
        data: {
          "oper_id": store.state.mqtt.mqtt_client_id.split('-')[1],
          "sp_id": store.state.mqtt.mqtt_client_id.split('-')[2],
          "merc_id": store.state.mqtt.mqtt_client_id.split('-')[3],
          "prop_id": store.state.mqtt.mqtt_client_id.split('-')[4],
          "park_id": store.state.mqtt.mqtt_client_id.split('-')[5],
          "sentry_id": store.state.mqtt.mqtt_client_id.split('-')[6] || 0
        },
      }
      // if (this.hasSentry()) {
      //   remain['bizContent']['sentry_id'] = this.sentry_id
      // }
      this.sendMqttReq(remain);
      // let remain = {
      //   "cmd": 1,
      //   "bizContent": {
      //     "park_id": this.park_id,
      //   },
      // }
      // if (this.hasSentry()) {
      //   remain['bizContent']['sentry_id'] = this.sentry_id
      // }
      // this.sendMqttReq(remain);
    },
    getSign(date) {
      // bus_type, client_id, client_type, cmd, pkg_id, pkg_type, timestamp, version
      const sign = store.state.mqtt.mqtt_recv_topics.split('/')[1] + store.state.mqtt.mqtt_client_id.split('-').pop() + store.state.mqtt.mqtt_client_id.split('-')[0] + 'gateway_heart_beat' + this.mqtt.clientId + 'REQ' + date + '1.0'
      return lmd5.hex_md5(sign)
    },
    //获取初始化数据
    getParkInitData() {
      const date = new Date().getTime()
      let remain = {
        pkg_id: this.mqtt.clientId,
        pkg_type: 'REQ',
        cmd: 'motor_init_data',
        bus_type: store.state.mqtt.mqtt_recv_topics.split('/')[1],
        client_type: store.state.mqtt.mqtt_client_id.split('-')[0],
        client_id: store.state.mqtt.mqtt_client_id.split('-').pop(),
        timestamp: date,
        version: '1.0',
        sign: this.getSign(date),
        data: {
          "oper_id": store.state.mqtt.mqtt_client_id.split('-')[1],
          "sp_id": store.state.mqtt.mqtt_client_id.split('-')[2],
          "merc_id": store.state.mqtt.mqtt_client_id.split('-')[3],
          "prop_id": store.state.mqtt.mqtt_client_id.split('-')[4],
          "park_id": store.state.mqtt.mqtt_client_id.split('-')[5],
          "sentry_id": store.state.mqtt.mqtt_client_id.split('-')[6] || 0
        },
      }
      // if (this.hasSentry()) {
      //   remain['bizContent']['sentry_id'] = this.sentry_id
      // }
      this.sendMqttReq(remain);
    },

    hasSentry() {
      return null != this.sentry_id && this.sentry_id.length > 0
    },

    heartBeat() { //发送心跳包
      const date = new Date().getTime()
      let remain = {
        pkg_id: this.mqtt.clientId,
        pkg_type: 'REQ',
        cmd: 'gateway_heart_beat',
        bus_type: store.state.mqtt.mqtt_recv_topics.split('/')[1],
        client_type: store.state.mqtt.mqtt_client_id.split('-')[0],
        client_id: store.state.mqtt.mqtt_client_id.split('-').pop(),
        timestamp: date,
        version: '1.0',
        sign: this.getSign(date),
        data: {
          "oper_id": store.state.mqtt.mqtt_client_id.split('-')[1],
          "sp_id": store.state.mqtt.mqtt_client_id.split('-')[2],
          "merc_id": store.state.mqtt.mqtt_client_id.split('-')[3],
          "prop_id": store.state.mqtt.mqtt_client_id.split('-')[4],
          "park_id": store.state.mqtt.mqtt_client_id.split('-')[5],
          "sentry_id": store.state.mqtt.mqtt_client_id.split('-')[6] || 0
        },
      }
      // let remain = {
      //   "cmd": 9,
      //   "bizContent": {
      //     "park_id": this.park_id,
      //   },
      // }
      // if (this.hasSentry()) {
      //   remain['bizContent']['sentry_id'] = this.sentry_id
      // }
      this.sendMqttReq(remain);
    },

    //连接丢失
    onConnectionLost(response) { //链接丢失，需要重连
      console.log("异常掉线，掉线信息为:" + response.errorMessage);
      if (response.errorMessage.includes('AMQJS0008I')) {
        if (sessionStorage.getItem('new')) {
          window.location.href = "about:blank";
        } else {
          this.MQTTConnect();
        }
      }
    },
    //接收到消息，处理
    onMessageArrived(message) {
      // var topics = message.destinationName;
      var msg = message.payloadString;
      console.log(" mqtt receive  " + msg)
      // let packData = JSON.parse(msg);
      // console.log(" mqtt receive  packData 1 " + packData)

      //判断主题，调用方法，这里可以订阅多个主题，在此处判断，接受不同的主题，调用不同的方法！
      // if (topics == this.subTopic) {
      let packData = JSON.parse(msg);
      /**
       * 返回结构
       *  {
       *    "code":0，//0 成功，非0表示异常
       *    "msg":"请求成功",
       *    "cmd":0, //对应的协议头
       *    "data":0, //具体的数据
       *  }
       */

      switch (packData.cmd) {
        case 'motor_init_data': //初始化数据的包
          //{"msg":"请求成功","code":0,"data":{"records":{"ent":{"type_name":"临停","dep_pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/c2554891-157a60d9/20220303/14/20220303_145432_874.jpg","ent_time":"2022-03-03 14:33:55","ent_state":"否","created_at":"2022-03-03 14:33:55","type":0,"prop_user_id":0,"dep_state":"否","updated_at":"2022-03-03 14:54:33","dep_gate_id":0,"id":2736,"state":1,"car_id":0,"prop_id":2,"dep_time":0,"park_id":17,"ent_gate_id":20,"ent_pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/e2b5b8f6-ce1ac5ad/20220303/14/20220303_143354_201.jpg","timing_state":"否","record_id":2736,"garage_id":0,"user_id":198,"ent_gate_name":"A通道-入口","car_number":"闽DL167M","pay_id":0,"car_size_type":1},"dep":{"type_name":"临停","dep_pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/c2554891-157a60d9/20220303/14/20220303_143343_058.jpg","ent_time":"2022-03-03 14:33:43","ent_state":"否","dep_gate_name":"A通道-出口","memo":"无入场补录","created_at":"2022-03-03 14:33:43","type":0,"prop_user_id":0,"dep_state":"否","updated_at":"2022-03-03 14:33:43","dep_gate_id":21,"price":0,"id":2735,"state":4,"car_id":0,"aly_pay_price":0,"pay_price":0,"prop_id":0,"dep_time":"2022-03-03 14:33:43","park_id":17,"ent_gate_id":-1,"ent_pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/c2554891-157a60d9/20220303/14/20220303_143343_058.jpg","timing_state":"否","record_id":2735,"garage_id":0,"user_id":0,"ent_gate_name":"","car_number":"无牌车","pay_id":0,"car_size_type":1}},"remain":{"left":3,"list":[{"name":"临时车","count":5},{"name":"月租车","count":1},{"name":"其他车","count":1}]}},"cmd":1}
          console.info(" mqtt receive  初始化数据推送 ", packData.data)
          this.remainData = packData.data.remain
          this.recordsEntData = packData.data.records.ent
          this.recordsDepData = packData.data.records.dep
          this.recordsDepData.gate_id = this.recordsDepData.dep_gate_id
          this.getUnpay(packData.data.records.dep, true)
          break;
        case 'motor_remain_notify': //余位信息推送
          console.info(" mqtt receive  余位信息推送 ", packData.data)
          this.remainData = packData.data
          break;
        case 'motor_in_notify': //最新的入场车辆推送
          //{"data":{"car_number":"闽D6987A","park_id":17,"gate_id":20,"is_fake":0,"pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/e2b5b8f6-ce1ac5ad/20220303/15/20220303_155739_244.jpg","record_time":1646294259,"car_type":"临停"},"cmd":2,"code":0,"msg":""}
          console.info(" mqtt receive  最新的入场车辆推送 ", packData.data)
          this.recordsEntData = Object.assign(packData.data, packData.data.extra)
          break;
        case 'motor_out_notify': //最新的出场车辆推送
          /**
           *{"data":{"car_number":"闽D6987A","park_id":17,"gate_id":21,"is_fake":0,"pic":"https://parking-lots.oss-cn-hangzhou.aliyuncs.com/picture/0/c2554891-157a60d9/20220303/15/20220303_155829_085.jpg","record_time":1646294309,"car_type":"临停","extra":{"aly_pay_price":0.0,"pay_price":0.0,"price":0.0,"ent_time":"2022-03-03 15:57:39","park_time":""}},"cmd":3,"code":0,"msg":""}
           */
          console.info(2222, " mqtt receive  最新的出场车辆推送 ", packData.data, packData.data.extra.aly_pay_price)

          if (this.recordsDepData.car_number == packData.data.car_number && this.recordsDepData.aly_pay_price > packData.data.extra.aly_pay_price) {
            packData.data.extra.aly_pay_price = this.recordsDepData.aly_pay_price
            packData.data.extra.pay_price = this.recordsDepData.pay_price
          }
          this.recordsDepData = Object.assign(packData.data, packData.data.extra)
          this.getUnpay(this.recordsDepData, true)
          break;
        case 'motor_pay_notify': //支付成功通知
          console.info(" mqtt receive  支付成功通知 ", packData.data)
          this.recordsDepData = packData.data
          // this.MQTTConnect();
          this.getUnpay(Object.assign(packData.data, packData.data.extra), false)
          break;
        case 'gateway_heart_beat': //心跳包
          console.info(" mqtt receive 心跳包 ", packData.data)
          break;
      }
      //添加
      // this.color = msg
      // }
      // else {
      //   return;
      // }
    },
    init() {
      if (this.getStore('lockState')) {
        this.lockState = this.getStore('lockState')
        this.$refs.dialogLock.init()
        return false
      }
      if (this.getStore('sentryState')) {
        this.$refs.dialogSentry.init()
        return false
      }
      this.getGate()
      this.getFreeOutList()
      // this.getUnpay()
    },
    getGate() {
      let form = {
        park_id: this.park_id,
        sentry_id: this.getStore('sentry_id')
      }
      API_index.getParkGate(form).then(res => {
        this.loading = false
        this.gateData = res
        this.$nextTick(async () => {
          for (let i in this.gateData) {
            // if (this.gateData[i].device && this.gateData[i].device.model_name == 'qy') {
            //   this.gateData[i].device.local_addr = `http://${this.gateData[i].device.local_addr}:8088/cgi-bin/video_cgi`
            // }
            if (this.gateData[i].device && (this.gateData[i].device.model_name == 'qy' || this.gateData[i].device.model_name == 'hc')) {
              // this.gateData[i].device.rtsp_url = 'rtsp://avd.qsparking.com:7554/live?device=16&channel=1&stream=0'
              await this.setRtsp(this.gateData[i].device, i)
              this.gateData[i].device.rtspFun.play(this.gateData[i].device.rtsp_url)
            } else if (this.gateData[i].device && this.gateData[i].device.model_name == 'ice') {
              let ws = null;
              let imgUrl = 'ws:' + this.gateData[i].device.local_addr + ':9999'
              ws = new WebSocket(imgUrl);
              ws.onopen = () => {
                console.log("open...");
              }
              let img = new Image();
              img.onload = () => {
                this.$refs['iceRef' + this.gateData[i].device.id].src = img.src;
              }
              ws.onmessage = (event) => {
                if (typeof (event.data) == "string") { //字符串数据
                  // var result = JSON.parse(event.data);
                } else {//视频图片流数据  
                  let reader = new FileReader();
                  reader.readAsDataURL(event.data);
                  reader.onload = (evn) => {
                    img.src = evn.target.result;
                  };
                }
              };
              ws.onerror = () => {
                console.log("error");
              }
              ws.onclose = () => {
                console.log("close");
              }
            } else if (this.gateData[i].device && this.gateData[i].device.local_addr.includes('.flv')) {
              this.playFlv(this.gateData[i], i)
            } else if (this.gateData[i].device && this.gateData[i].device.local_addr.includes('.m3u8')) {
              this.play(this.gateData[i].id, i)
            } else if (this.gateData[i].device && this.gateData[i].device.local_addr.includes('.php')) {
              this.getMqttFlv(this.gateData[i], i)
            } else if (this.gateData[i].device && this.gateData[i].device.local_addr) {
              this.getHttp(this.gateData[i], i)
            }
          }
        });
      })

    },
    getMqttFlv(data, i) {
      axios({
        method: 'post',
        url: data.device.local_addr,
        data: {
          type: 'get_live_stream_type',
          module: 'BUS_WEB_REQUEST'
        },
        headers: {
          // 'Access-Control-Allow-Origin': '*'
        },
      }).then((res) => {
        if (res.status == 200) {
          var pubkey = Base64.decode(res.data.body.pubkey)
          let encryptStr = new JSEncrypt()
          encryptStr.setPublicKey(pubkey)
          var str = 'admin:admin'
          var token = encodeURIComponent(encryptStr.encrypt(str.toString()))
          data.device.local_addr = 'ws://' + data.device.local_addr.split(':')[1].split('/').pop() + ':' + res.data.body.port + '/ws.flv?token=' + token
          this.playFlv(data, i)
        }
      }).catch(() => {
        this.flvfail = true
      });
    },
    getHttp(data) {
      //链接服务端
      let ws = new WebSocket("ws://" + data.device.local_addr + ":9080/ws");

      //配置客户端连接成功的回调方法
      ws.onopen = function () {
        console.log("open...");
        ws.send({
          type: "login",
          module: "BUS_WEB_REQUEST",
          user_info: "iotCXVNTU1OUr7Eiif3i/C7/tg=="
        });
        ws.send({
          type: "reg_live_mjpeg",
          module: "BUS_WEB_REQUEST"
        });
      };

      //配置客户端处理消息的回调方法
      ws.onmessage = function (event) {
        let img_1 = new Image();
        img_1.onload = function () {
          data.imageSrc = img_1.src;
        }
        //创建FileReader对象，该对象时html5中的特有对象，详细用法可以//参照html5相关资料
        let reader = new FileReader();

        //设置FileReader对象的读取文件回调方法
        reader.onload = function (eve) {
          //判断文件是否读取完成
          if (eve.target.readyState == FileReader.DONE) {
            //读取文件完成后，创建img标签来显示服务端传来的字节数//组

            //读取文件完成后内容放在this===当前
            //fileReader对象的result属性中，将该内容赋值img标签//浏览器就可以自动解析内容格式并且渲染在浏览器中
            img_1.src = this.result;
            //将标签添加到id为show的div中否则，即便是有img也看不见
            // document.getElementById("show") = img;
          }
        };
        //调用FileReader的readAsDataURL的方法自动就会触发onload事件
        reader.readAsDataURL(event.data);
      };
    },
    getUnpay(data, state) {
      let list = this.getStore('pendingList') && JSON.parse(this.getStore('pendingList')) || []
      if (data.dep_gate_id) {
        data.gate_id = data.dep_gate_id
      }
      let index = list.findIndex(item => (item.gate_id == data.gate_id))
      if (state && index < 0 && data.pay_price > 0) {
        list.push(data)
      } else if (state && index >= 0 && data.pay_price > 0) {
        list[index] = data
      } else if (!state && index >= 0) {
        // this.recordsDepData = list[index]
        this.recordsDepData.pay_price = data.pay_price
        list.splice(index, 1)
        if (list.length > 0) {
          this.recordsDepData = list[0]
        }
      } else if (!state && this.recordsDepData.car_number == data.car_number) {
        this.recordsDepData.pay_price = data.pay_price
      }
      this.pendingList = list
      this.setStore('pendingList', list)


      // API_index.getRecordUnpay({ park_id: this.park_id }).then(res => {
      //   this.pendingList = res.data
      // })
    },
    play(id, index) {
      console.log(1112, id)
      let myVideo = videojs("myVideo" + id + index, {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        hls: {
          withCredentials: true
        }
      });
      myVideo.children_[0].controls = true
      // console.log(this.myVideo)

    },
    playFlv(data) {
      var container = document.getElementById('video' + data.id)
      let player = new window.Jessibuca({ container: container, videoBuffer: 0.2, isResize: false, forceNoOffscreen: true, isNotMute: false, timeout: 10 })
      player.on('load', function () {
        player.play(data.device.local_addr)
      })
      player.on('error', () => {
        // alert("播放错误")
        this.playFlv(data)
        // location.reload();
      })
      player.on('timeout', () => {
        // alert("播放超时")
        this.playFlv(data)
        // location.reload();
      })
      /*
        new window.FlvJsPlayer({
          id: 'video' + data.id,
          isLive: true,
          playsinline: true,
          url: data.device.local_addr,
          autoplay: true,
          autoplayMuted: true,
          width: 370,
          height: 280,
        });
      */
    },
    async setRtsp(data, index) {
      let that = this
      data.rtspFun = await new Rtsp2WPlayer({
        //RTSPtoWebRTC服务器地址
        serverPath: "http://127.0.0.1:8083"// location.protocol + '//' + location.hostname + ':9083'

        //视频容器ID
        , containerId: 'rtsp' + data.id + index

        //是否在控制台输出日志
        , logEnabled: true

        //视频控件样式：HTML元素video的样式
        , style: {
          "controls": true
        }

        /**
         * 当视频连接状态发送变化时的事件回调，如果不关注该事件，可以忽略
         * @param state 连接状态
            connecting:正在连接，connected:连接成功，disconnected：连接断开
         */
        , onconnectionstatechange: function (state) {
          console.log('连接状态：', state);
          if ("disconnected" == state || "failed" == state) {
            that.flvfail = true
            // setTimeout(() => {
            //   console.log('重连...');
            //   this.setRtsp(data, index)
            // }, 5000);
          }
        }
      });
    },
    handleQuick() {
      let form = {
        park_id: this.recordsEntData.park_id,
        gate_id: this.recordsEntData.ent_gate_id || this.recordsEntData.gate_id,
        car_number: this.recordsEntData.car_number,
        actual_open: 1,
        memo: this.recordsEntData.memo,
        ent_time: this.recordsEntData.ent_time,
        pic: this.recordsEntData.ent_pic || this.recordsEntData.pic

      }
      this.dialogVisibleLookup = true
      API_index.postRecordManualIn(form).then(res => {
        this.dialogVisibleLookup = false
        this.recordsEntData.memo = ''
        if (this.type == 'out') {
          this.dialogVisibleInfo = true
          this.carInfo = { ...res.record, ...res.price_info }
        }
      }).catch(() => {
        this.dialogVisibleLookup = false
      })
    },
    getFreeOutList() {
      API_index.getRecordFreeOut({ park_id: this.park_id }).then(res => {
        this.freeOutList = res
        this.freeOut = this.freeOutList[0]
      })
    },
    handleRecognize(e, gate_id) {
      let form = {
        park_id: this.park_id,
        gate_id,
      }
      // this.recognizeLoading = true
      API_index.postGateTrigger(form).then(() => {
        this.recognizeLoading = false
        this.$message({
          type: 'success',
          message: '识别成功!',
        })
      }).catch(() => {
        this.recognizeLoading = false
      })
    },
    handleOpenAndClose(e, gate_id, action) {
      // this.$confirm('确认' + (action ? '落' : '开') + '闸吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      let form = {
        park_id: this.park_id,
        gate_id,
        action
      }
      console.log(e)
      if (e.target.localName == 'button') {
        e.target.disabled = true
      } else if (e.target.parentElement.localName == 'button') {
        e.target.parentElement.disabled = true
      }
      // this.openAndCloseLoading = true
      API_index.postParkGateAction(form).then(() => {
        // this.openAndCloseLoading = false
        if (e.target.localName == 'button') {
          e.target.disabled = false
        } else if (e.target.parentElement.localName == 'button') {
          e.target.parentElement.disabled = false
        }
        this.$message({
          type: 'success',
          message: (action ? '落' : '开') + '闸成功!',
        })
      }).catch(() => {
        // this.openAndCloseLoading = false
        if (e.target.localName == 'button') {
          e.target.disabled = false
        } else if (e.target.parentElement.localName == 'button') {
          e.target.parentElement.disabled = false
        }
      })
    },
    handlePrePay(e) {

      if (!e.park_id) {
        return false
      }
      console.log(1222, e)
      let form = {
        park_id: e.park_id,
        gate_id: e.dep_gate_id || e.gate_id,
        car_number: e.car_number,
        pay_price: e.pay_price,
        pay_type: '现金',
        record_id: e.record_id,
        prop_rule_id: e.prop_rule_id,
        gate_open_log_id: e.gate_open_log_id,
        dep_param_id: e.dep_param_id,
        sign: e.sign,
      }
      this.prePayLoading = true
      API_index.getRecordAdminPrePay(form).then(() => {
        // this.getUnpay(form, false)
        this.prePayLoading = false

        this.$message({
          type: 'success',
          message: '已放行',
        })
      }).catch(() => {
        this.prePayLoading = false
      })
    },
    handelDispose(e) {
      this.disposeLoading = true
      const form = {
        park_id: e.park_id,
        gate_id: e.dep_gate_id || e.gate_id,
        car_number: e.car_number,
      }
      API_index.getRecordUndo(form).then(() => {
        this.disposeLoading = false
        this.disConnect();
        this.$nextTick(() => {
          this.MQTTConnect();
        })
        this.getUnpay(e, false)

        this.$message({
          type: 'success',
          message: '操作成功',
        })
      }).catch(() => {
        this.disposeLoading = false
      })
    },
    handelRefresh() {
      this.$router.go()
    },
    handleFreeOut(e) {


      if (!e.park_id) {
        return false
      }
      if (!this.freeOut) {
        this.$message({
          type: 'error',
          message: '请选择免费放行原因',
        })
        return false
      }
      let form = {
        park_id: e.park_id,
        gate_id: e.dep_gate_id || e.gate_id,
        car_number: e.car_number,
        record_id: e.record_id,
        memo: this.freeOut,
      }
      this.freeOutLoading = true
      API_index.postRecordManualFreeOut(form).then(() => {
        this.freeOutLoading = false
        this.freeOut = ''
        this.recordsDepData.pay_price = ''
        this.getUnpay(this.recordsDepData, false) // 清除待处理

        this.$message({
          type: 'success',
          message: '已免费放行',
        })

      }).catch(() => {
        this.freeOutLoading = false
      })
    },
    handleOutAndEnterRecord(state) {
      if (state == 'enter') {
        this.outEnterTitle = '入场记录'
      } else if (state == 'out') {
        this.outEnterTitle = '出场记录'
      } else if (state == 'bepresent') {
        this.outEnterTitle = '在场车辆'
      }
      this.$refs.dialogOutEnterRecord.init(state)
    },
    handleOutEnter(type, car_number) {
      let list = []
      if (type == 'out') {
        this.outEnterTitle = '手动出场'

        for (let i in this.gateData) {
          if (this.gateData[i].type == '出口') {
            list.push(this.gateData[i])
          }
        }
      } else if (type == 'enter') {
        this.outEnterTitle = '手动入场'

        for (let i in this.gateData) {
          if (this.gateData[i].type == '入口') {
            list.push(this.gateData[i])
          }
        }
      }
      this.$refs.dialogOutEnter.init(type, list, car_number)
    },
    handlePending(data) {
      // this.outEnterTitle = '待处理'
      // this.$refs.dialogOutEnter.init('pending', '', data)
      this.recordsDepData = data

    },
    async confirmSentry() {
      await store.dispatch('getMqtt', { park_id: this.getStore('park_id'), sentry_id: this.getStore('sentry_id') })
      this.init()
      this.subTopic = store.state.mqtt.mqtt_recv_topics.split(';')[0]
      this.pubTopic = store.state.mqtt.mqtt_send_topic
      this.MQTTConnect();
      this.timer = setInterval(this.heartBeat, store.state.mqtt.mqtt_keep_live * 1000);//30秒发送一次心跳
    },
    confirmLock() {
      this.lockState = false
      if (this.gateData.length == 0) {
        this.init()
      } else {
        this.loading = false
      }
    },
    appearance(car_number) {
      this.handleOutEnter('out', car_number)

    },
    previewImg(url) {
      this.$refs.PreviewImg.init(url)
    },

    handleLoginout() {
      this.$confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        API_login.postUserLoginout().then(() => {
          // this.clearStore()
          this.$message({
            type: 'success',
            message: '退出登录成功!',
          })
          setTimeout(() => {
            this.$store.dispatch('loginOut').then(() => {
              // location.reload()
            })
          }, 1000)

        })
      }).catch(() => { })
    },
    handleFullScreen() {
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
        this.isFullScreen = false
      } else {
        const docElm = document.documentElement
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen()
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen()
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen()
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen()
        }

        this.isFullScreen = true
      }
    },
    handleLock() {
      this.$confirm('确认锁屏吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let form = {
          park_id: this.park_id,
        }
        API_login.getUserLock(form).then(() => {
          this.lockState = true
          this.loading = true
          this.setStore('lockState', true)
          this.$refs.dialogLock.init()
        })
      }).catch(() => { })
    }

  }
}
</script>
<style lang="scss" scoped>
.home {
  .head {
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    background-color: $primary;
    font-size: 16px;
    color: #fff;

    .head-l {
      cursor: pointer;

      i {
        margin-right: 10px;
      }
    }

    .head-title {
      font-size: 30px;
    }

    button {
      width: 60px;
      height: 30px;
      border-radius: 4px;
      margin-left: 10px;
    }
  }
}

.content {
  padding: 16px 0 14px 16px;
}

.video-box {
  width: 70%;

  &.video-carousel {
    width: 100%;
  }

  :deep(.el-carousel__indicators--outside) {
    margin-top: -5px;
    height: 25px;
    overflow: hidden;

    li {
      margin-top: 5px;
    }
  }

  li {
    width: 650px;
    // height: 444px;
    margin: 0 8px;
    margin-bottom: 16px;

    .video-item {
      background-color: #000;
      color: #999;
      text-align: center;
      font-size: 20px;
      border: none;

      label {
        margin-top: 20px;
        display: inline-block;

        &.off-line {
          margin-top: 50px;
          font-size: 30px;
        }
      }
    }

    .iframe {
      width: 100%;
      height: 385px;
      overflow: hidden;

      iframe.video-item {
        width: calc(100% + 20px) !important;
        height: calc(100% + 20px) !important;
      }
    }

    &.li3 {
      width: 425px;

      // height: 300px;
      .video-item,
      img {
        position: relative;
        height: 243px !important;

        :deep(video) {
          height: 243px !important;
        }
      }

      .iframe {
        width: 100%;
        height: 243px !important;
      }
    }

    &.li4 {
      width: 310px;

      // height: 218px;
      .video-item,
      img {
        position: relative;
        height: 175px !important;

        :deep(video) {
          height: 175px !important;
        }
      }

      .iframe {
        width: 100%;
        height: 175px !important;
      }
    }

    .video-item,
    img {
      position: relative;
      width: 100% !important;
      height: 385px !important;

      :deep(video) {
        height: 385px !important;
      }

      // height: 0 !important;
      // padding-bottom: calc(100% * 3 / 4);
      // :deep(video) {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      // }
    }

    .myVideo21-dimensions {
      width: 100%;
      height: 280px;
      overflow: hidden;

      video {
        height: 100%;
      }
    }

    // &:nth-child(odd) {
    //   margin-right: 16px;
    // }

    .funtion {
      display: block;
      padding: 10px;
      height: auto;
      line-height: 1.5;
      background-color: #fff;

      .funtion-title {
        font-size: 16px;
        font-weight: bold;
      }

      .funtion-button {
        margin-top: 4px;
        justify-content: space-between;
      }
    }
  }

  // img,
  // video {
  //   width: 100%;
  //   // height: 385px;
  //   // object-fit: cover;
  // }
}

.info {
  width: 30%;
  height: 905px;

  .info-statistics {
    background-color: #fff;
    height: 105px;
    padding: 0 30px;
    text-align: center;
    margin-bottom: 15px;

    label {
      color: $primary;
    }

    li:first-child {
      label {
        color: $warning;
      }
    }
  }

  .info-car {
    height: 784px;
    padding: 5px 30px;
    background-color: #fff;

    .info-car-title {
      height: 50px;
      line-height: 50px;
      color: $primary;
      border-bottom: 1px solid $primary;

      .pending {
        li {
          line-height: 60px;
        }
      }
    }

    .info-car-data {
      padding: 20px 0;

      li {
        width: 50%;
        line-height: 40px;

        label {
          width: 5em;
        }

        .el-image {
          width: 0;
          height: 0;
        }

        p {
          flex: 1;

          &.preview-img {
            color: $primary;
            cursor: pointer;
          }
        }

        &.info-car-cost {

          // padding: 20px 0;
          p {
            display: inline-block;
            margin-top: 20px;
            font-size: 60px;
            color: $danger;
          }
        }

        &.quick {
          // .el-button {
          //   position: absolute;
          //   margin-top: -30px;
          // }
        }
      }

      .info-car-button {
        margin-bottom: 20px;

        .el-button {
          width: 146px;
          height: 55px;
          border-radius: 0;
        }
      }
    }
  }
}

.footer {
  padding: 10px 30px;
  background-color: #fff;
  font-size: 20px;

  ul {
    display: flex;

    li {
      color: #000;
      border-right: 1px solid #ccd5dd;
      padding: 0 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 24px;
        margin-right: 5px;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}
</style>

<style>
.vjs-loading-spinner,
.vjs-big-play-button,
.vjs-control-bar,
.vjs-modal-dialog {
  display: none;
}

.el-carousel {
  width: 100%;
}
</style>
